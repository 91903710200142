import Vue from 'vue'
import Router from 'vue-router'
import About from "./views/About";

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Index',
      component: About
    },
    {
      path: '/app',
      name: 'Application',
      component: () => import('./views/Home.vue')
    },
    {
      path: '/contacts',
      name: 'Contacts',
      component: () => import('./views/Contact.vue')
    },
    {
      path: '/help',
      name: 'Help',
      component: () => import('./views/Help.vue')
    },
    {
      path: '/citing',
      name: 'Citing',
      component: () => import('./views/Citing.vue')
    },
    {
      path: '/authors',
      name: 'Authors',
      component: () => import('./views/Authors.vue')
    }
  ]
})


// export default new Router({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes: [
//     {
//       path: '/',
//       name: 'home',
//       component: Home
//     },
//     {
//       path: '/about',
//       name: 'about',
//       // route level code-splitting
//       // this generates a separate chunk (about.[hash].js) for this route
//       // which is lazy-loaded when the route is visited.
//       component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
//     }
//   ]
// })
