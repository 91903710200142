<template>
  <v-app id="app">
    <v-app-bar
        app
        color="primary"
        dark
    >
      <v-app-bar-nav-icon @click.stop="showNavigation = !showNavigation">
      </v-app-bar-nav-icon>
      <v-toolbar-title>MCOT {{$route.name}}</v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer
      v-model="showNavigation"
      app

    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            Menu
          </v-list-item-title>

        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
        dense
        nav
      >
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          v-bind:to="item.to"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title >{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item href="https://github.com/academiq/mcot-kernel" target="_blank">
          <v-list-item-icon><v-icon>mdi-console</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>  Download & Install</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>


    <v-main>
      <v-container
      fluid>
        <v-row>
          <v-col cols="0" sm="0"/>
          <v-col cols="12" sm="12">
              <router-view/>

          </v-col>
          <v-col cols="0" sm="0"/>
        </v-row>

      </v-container>
    </v-main>
    <v-footer
      color="indigo"
      app
      absolute
    >
      <v-spacer></v-spacer>
      <span class="white--text">&copy; Institute of Cytology and Genetics SB RAS, 2020</span>
    </v-footer>

  </v-app>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    name: 'MaterialIcons',
    showNavigation: null,
    items: [
      { title: 'About', icon: 'mdi-note', to: '/' },
      { title: 'Application', icon: 'mdi-view-dashboard', to: '/app' },
      { title: 'Help', icon: 'mdi-help', to: '/help'},
      { title: "Citing", icon: 'mdi-electron-framework',to:'/citing'},
      { title: 'Authors', icon: 'mdi-account-group', to:'/authors'},
      { title: 'Contacts', icon: 'mdi-lifebuoy', to: '/contacts'},

    ],
  right: null,
  })
}
</script>

<style>
/* #app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
  margin-left: 45px;
} */
</style>
