<template>
    <div id='index'>
      <v-row>
        <v-col cols="0" sm="1"/>
          <v-col cols="12" sm="10">
        <p class="text-justify" style="display:inline;font-size:25px">


 <img style="max-width:22%;" src="../../public/mcot_logo.png" class="leftimg" />
            MCOT (Motifs Co-Occurrence Tool) is an approach for transcription factors
            (TFs) complexes prediction through motifs co-occurrence discovery. MCOT
            detects overrepresented pairs of TF binding sites (motifs) with an overlap
            or a spacer (composite elements, CEs) in a single ChIP-seq dataset
            (Levitsky et al, <a href="https://doi.org/10.1093/nar/gkz800">2019</a>,
          <a href="https://www.mdpi.com/1422-0067/21/17/6023">2020</a>).
            Typically, input data provides from 500 to
            ~10000 sequences of length from 200 bp to 1 kbp. Each potential CE
            contains the anchor motif of ChIP-seq target TF and another motif of
            partner TF. Optionally, one partner motif can be tested, or simultaneously
            hundreds of partner motifs form a public library are checked.
            Identical/distinct anchor and partner motifs imply a search of
            homotypic/heterotypic CEs. Also, MCOT checks whether CEs respect to
            significantly higher conservation of anchor or partner motif.
        </p>



              <p style="margin-top: 30px">Levitsky V, Zemlyanskaya E, Oshchepkov D, Podkolodnaya O, Ignatieva E,
    Grosse I, Mironova V, Merkulova T. A single ChIP-seq dataset is sufficient
    for comprehensive analysis of motifs co-occurrence with MCOT package.
    Nucleic Acids Res. 2019, 47(21):e139. <a href="https://academic.oup.com/nar/article-lookup/doi/10.1093/nar/gkz800">doi:10.1093/nar/gkz800</a></p>

            <p>
              Levitsky V, Oshchepkov D, Zemlyanskaya E, Merkulova T. Asymmetric
conservation within pairs of co-occurred motifs mediates weak direct
binding of transcription factors in ChIP-seq data. Int J Mol Sci. 2020,
              21(17):6023. <a href="https://www.mdpi.com/1422-0067/21/17/6023">doi:10.3390/ijms21176023</a>
            </p>
	      <p>Levitsky VG, Mukhin AM, Oshchepkov DY, Zemlyanskaya EV, Lashin SA. Web-MCOT Server for Motif Co-Occurrence Search in ChIP-Seq Data. Int. J. Mol. Sci. 2022, 23: 8981<a href=https://doi.org/10.3390/ijms23168981>doi:10.3390/ijms23168981</a></p>
    </v-col>
          <v-col cols="0" sm="1"/>

      </v-row>
      <v-row align="center" justify="center">
          <v-btn
                  to='/app'
                  min-width="85%"
                  color="primary"
                  x-large
          >Open Application</v-btn>
      </v-row>




    </div>
</template>

<script>
export default {
  name: 'index'
}
</script>

<style>
    .leftimg {
    float:left; /* Выравнивание по левому краю */

    margin: 10px 10px 10px 0; /* Отступы вокруг картинки */
   }
</style>
